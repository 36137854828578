<template>
  <div>
    <div id="container1">
      <!-- <div class="element">
        <div class="symbol">INTRODUCE</div>
        <div class="details">
          Hello, my name is Lee seunghak .<br />"I am a person who dreams of
          becoming a great web developer from Korea.<br />Even if there are a
          lot of missing parts, I ask for your attention.<br />I will always be
          trying.<br />
        </div>
        <div class="lastComment">
          Thank you very much for your precious time.<br />" "Have fun looking
          around and be happy today!
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import {
  CSS3DRenderer,
  CSS3DObject
} from "three/examples/jsm/renderers/CSS3DRenderer";
//Caemra , scene, rendere1, rendere2
var camera, scene, renderer, renderer1, container;
var mouseX = 0,
  mouseY = 0;
var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;
var objects = [];
var targets = { table: [] };

export default {
  mounted() {
    this.init();
    this.animate();
  },
  methods: {
    init() {
      container = document.getElementById("container1");

      camera = new THREE.PerspectiveCamera(
        20,
        window.innerWidth / (window.innerHeight / 2),
        1,
        500
      );
      camera.position.z = 4000;

      scene = new THREE.Scene();

      var element = document.createElement("div");
      element.className = "element123";
      element.style.backgroundColor = "rgba(0,127,127,0.3)";

      var symbol = document.createElement("div");
      symbol.className = "symbol";
      symbol.textContent = "INTRODUCE";

      var details = document.createElement("div");
      details.className = "details";
      details.innerHTML =
        "Hello, my name is Lee seunghak .<br />I am a person who dreams of becoming a great web developer from Korea.<br />Even if there are a lot of missing parts, I ask for your attention.<br />I will always be trying.<br />";

      var lastComment = document.createElement("div");
      lastComment.className = "lastComment";
      lastComment.innerHTML =
        "Thank you very much for your precious time.<br /> Have fun looking around and be happy today!";

      element.appendChild(symbol);
      element.appendChild(details);
      element.appendChild(lastComment);

      var object = new CSS3DObject(element);
      object.position.x = 0;
      object.position.y = 200;
      scene.add(object);

      // objects.push(object);
      // var object = new THREE.Object3D();
      // object.position.x = 600 - 1330;
      // object.position.y = -(6 * 180) + 990;

      targets.table.push(object);
      renderer = new CSS3DRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(renderer.domElement);

      document.addEventListener("mousemove", this.onDocumentMouseMove, false);
      window.addEventListener("resize", this.onWindowResize, false);
    },

    onWindowResize() {
      windowHalfX = window.innerWidth;
      windowHalfY = window.innerHeight;

      camera.aspect = window.innerWidth / window.innerHeight;

      renderer.setSize(window.innerWidth, window.innerHeight);
    },
    onDocumentMouseMove(event) {
      mouseX = event.clientX - windowHalfX;
      mouseY = event.clientY - windowHalfY;
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.render();
    },
    render() {
      camera.position.x += (mouseX - camera.position.x) * 0.05;
      camera.position.y += (-mouseY - camera.position.y) * 0.05;

      camera.lookAt(scene.position);
      renderer.render(scene, camera);
    }
  }
};
</script>

<style>
canvas {
  display: block;
}
a,
button,
input,
select {
  pointer-events: auto;
}
#container1 {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}
.element123 {
  padding: 50px 50px;
  position: relative;
  width: 80%;
  height: 800px;
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.5);
  border: 1px solid rgba(127, 255, 255, 0.25);
  font-family: Helvetica, sans-serif;
  text-align: center;
  line-height: normal;
  cursor: default;
}

.element123:hover {
  box-shadow: 0px 0px 12px rgba(0, 255, 255, 0.75);
  border: 1px solid rgba(127, 255, 255, 0.75);
}

.element123 .symbol {
  top: 10px;
  left: 0px;
  right: 0px;
  font-size: 60px;
  font-weight: bold;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
}

.lastComment {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  font-size: 50px;
  color: rgba(255, 255, 255, 0.75);
  text-shadow: 0 0 10px rgba(0, 255, 255, 0.95);
}
.element123 .details {
  position: absolute;
  top: 150px;
  left: 0px;
  right: 0px;
  font-size: 50px;
  color: rgba(127, 255, 255, 0.75);
}
@media (max-width: 1615px) {
  .element123 .details {
    font-size: 45px;
  }
}
@media (max-width: 1153px) {
  .element123 {
    width: 100%;
    padding: 20px 20px;
  }
  .element123 .details {
    margin-top: 30px;
  }
  .lastComment {
    font-size: 45px;
    margin-bottom: 30px;
  }
}
@media (max-width: 1061px) {
  .element123 .symbol {
    margin-top: 20px;
    font-size: 50px;
  }
  .element123 .details {
    font-size: 40px;
  }
  .lastComment {
    font-size: 40px;
    margin-bottom: 30px;
  }
}
@media (max-width: 984px) {
  .element123 .symbol {
    font-size: 45px;
  }
  .element123 .details {
    font-size: 35px;
  }
  .lastComment {
    font-size: 35px;
    margin-bottom: 60px;
  }
}

@media (max-width: 845px) {
  .element123 {
    width: 1000px;
  }
  .element123 .symbol {
    font-size: 42px;
  }
  .element123 .details {
    font-size: 35px;
    line-height: 2.5rem;
  }
  .lastComment {
    font-size: 37px;
    margin-bottom: 60px;
    line-height: 2.5rem;
  }
}
@media (max-width: 584px) {
  .element123 {
    width: 750px;
  }
  .element123 .symbol {
    font-size: 35px;
  }
  .element123 .details {
    line-height: 3rem;
    font-size: 30px;
  }
  .lastComment {
    line-height: 3rem;
    font-size: 34px;
    margin-bottom: 60px;
  }
}

@media (max-width: 443px) {
  .element123 {
    width: 500px;
  }
  .element123 .symbol {
    font-size: 33px;
  }
  .element123 .details {
    line-height: 3rem;
    font-size: 27px;
  }
  .lastComment {
    line-height: 3rem;
    font-size: 28px;
    margin-bottom: 60px;
  }
}
</style>
